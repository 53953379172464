<template>
	<div class="spread_coupon">
		<el-card>
			<el-row :gutter="20" class="left">
				<el-col :span="24">
					<span style="color:#666">优惠券</span>
				</el-col>
			</el-row>
			<el-divider></el-divider>
			<el-row :gutter="10" class="left mb">
				<el-col :span="6">
						<el-input v-model="searchYHQ" placeholder="请输入优惠券名称"></el-input>
				</el-col>
				<el-col :span="18">
					<el-button type="primary" icon="el-icon-search" @click="search">搜索</el-button>
					<el-button type="default" icon="el-icon-refresh" @click="reset">重置</el-button>
					<el-button type="primary" icon="el-icon-plus" @click="addYHQ">新增优惠券</el-button>
				</el-col>
			</el-row>
			<el-table :data="yhq_data" :default-sort="{prop:'coupon_sort',order:'descending'}" :header-cell-style="{ background: '#eef1f6', color: '#606266' }" style="min-width: 100%">
				<!-- <el-table-column type="index" label="ID" width="100px" fixed></el-table-column> -->
				<el-table-column prop="coupon_name" label="优惠券名称" fixed></el-table-column>
				<el-table-column prop="coupon_type_name" label="类型" align="center"></el-table-column>
				<el-table-column prop="coupon_worth_str" width="150" label="券额" align="center"></el-table-column>
				<el-table-column prop="coupon_count" label="数量" align="center"></el-table-column>
				<el-table-column prop="range" label="时间范围" width="250">
					<template slot-scope="scope">
						<div>
							{{scope.row.start_time}} - {{scope.row.end_time}}
						</div>
					</template>
				</el-table-column>
				<el-table-column prop="time_status" label="是否正常">
					<template slot-scope="scope">
						<div>
							<el-tag type="success" v-if="scope.row.time_status>0">正常</el-tag>
							<el-tag type="danger" v-else>已过期</el-tag>
						</div>
					</template>
				</el-table-column>
				<el-table-column prop="coupon_sort" label="排序值" align="center"></el-table-column>
				<el-table-column prop="add_time" label="创建时间"></el-table-column>
				<el-table-column prop="is_on_sale" label="是否上架">
					<template slot-scope="scope">
						<div>
							<el-switch v-model="scope.row.is_on_sale" @change="changeState(scope.row)"></el-switch>
						</div>
					</template>
				</el-table-column>
				<el-table-column prop="is_on_sale" label="类型">
					<template slot-scope="scope">
						<div>
							<el-tag type="primary" v-if="scope.row.for_type==1">平台</el-tag>
							<el-tag type="warning" v-if="scope.row.for_type==2">技师</el-tag>
							<el-tag type="danger" v-if="scope.row.for_type==3">门店</el-tag>
						</div>
					</template>
				</el-table-column>
				<el-table-column label="操作" fixed="right" width="300" align="center">
					<template slot-scope="scope">
						<div class="btns">
							<el-button size="mini" type="success" plain v-if="!scope.row.is_ready"
							 @click="readyCoupon(scope.row.coupon_id)">生成</el-button>
							 <el-button size="mini" type="primary" plain v-if="scope.row.is_ready" @click="see(scope.row.coupon_id)">查看</el-button>
							<el-button size="mini" type="primary" plain @click="edit(scope.row.coupon_id)">编辑</el-button>
							<el-button size="mini" type="danger" plain @click="remove(scope.row.coupon_id)">删除</el-button>
							<el-button size="mini" type="success" plain v-if="scope.row.is_ready"
							 @click="send(scope.row)">指定派发</el-button>
						</div>
					</template>
				</el-table-column>
			</el-table>
			<Pagination :queryInfo="queryInfo" :total="total" @handleSizeChange="handleSizeChange" @handleCurrentChange="handleCurrentChange"></Pagination>
			<!-- 派发对话框 -->
			<el-dialog title="指定派发" center width="66%" :visible.sync="sendVisible" top="5vh">
				<div class="warning mb">
					<p>优惠券名称:<span>{{yhq_detail.coupon_name}}</span></p>
					<p>使用条件:<span>{{yhq_detail.coupon_worth_str}}</span></p>
				</div>
				<el-row :gutter="10" class="left mb">
					<el-col :span="8">
						<el-form label-width="80px">
							<el-form-item label="输入查询">
								<el-input v-model="searchUser" placeholder="请输入用户昵称/手机号"></el-input>
							</el-form-item>
						</el-form>
					</el-col>
					<el-col :span="16">
						<el-button type="primary" icon="el-icon-search" @click="userSearch">搜索</el-button>
						<el-button type="default" icon="el-icon-refresh" @click="resetUser">重置</el-button>
					</el-col>
				</el-row>
				<el-table ref="userTable" :row-key="getRowKey" :data="user_data" :header-cell-style="{ background: '#eef1f6', color: '#606266' }" @selection-change="handleSelectionChange">
					<el-table-column type="selection"></el-table-column>
					<el-table-column prop="wx_id" label="ID"></el-table-column>
					<el-table-column prop="nickName" label="用户昵称"></el-table-column>
					<el-table-column label="用户头像">
						<template slot-scope="scope">
							<div>
								<img style="width:60px;height:60px" :src="scope.row.avatarUrl" alt="">
							</div>
						</template>
					</el-table-column>
					<el-table-column prop="phoneNumber" label="手机号"></el-table-column>
					<!-- <el-table-column label="卡券数量">
						<template slot-scope="scope">
							<div>
								<el-input v-model.number="yhq_count[scope.$index]" placeholder="请输入卡券数量"></el-input>
							</div>
						</template>
					</el-table-column> -->
				</el-table>
				<Pagination :queryInfo="queryInfo_send" :total="send_total" @handleSizeChange="handleSizeChange_send" @handleCurrentChange="handleCurrentChange_send"></Pagination>
				<div style="text-align:center">
					<el-button type="default" @click="close">取消</el-button>
					<el-button type="primary" @click="submit">确定</el-button>
				</div>
			</el-dialog>
		</el-card>
	</div>
</template>

<script>
	let that;
	import Pagination from '@/components/Pagination/Pagination.vue'
	import {compareDateTime} from '@/api/utils'
	export default {
		components: {
			Pagination
		},
		data() {
			return {
				queryInfo: {
					query: "",
					pagenum: 1,
					pagesize: 10,
				},
				total: 0,
				queryInfo_send: {
					query: "",
					pagenum: 1,
					pagesize: 10,
				},
				send_total: 0,
				searchYHQ: '',
				searchUser: '',
				yhq_data: [],
				user_data: [],
				sendVisible: false,
				yhq_detail: {},
				yhq_count: [],
				selectUser: []
			}
		},
		computed: {
			tableCount() {
				return this.user_data.length
			}
		},
		created() {
			if(!this.$store.state.userInfo) {
				// 防止未登录
				this.$router.push({
					path: '/login'
				})
			} else {
				that = this;
				this.getCouponList();
			}
		},
		methods: {
			getCouponList() {
				var url = 'coupon/coupon_list';
				let params = {
					coupon_name: this.searchYHQ,
					size: this.queryInfo.pagesize,
					page: this.queryInfo.pagenum,
				};
				this.fd_post(url, params).then((res) => {
					if(res.status) {
						that.total = res.total;
						that.yhq_data = res.list.map(item => {
							item.time_status = compareDateTime(item.end_time)
							return item
						});
						console.log(that.yhq_data)
					} else {
						that.$message.error(res.msg);
					}
				}).catch((err) => {
					that.$message.error('网络错误');
				});
			},
			getUserList() {
				var url = 'custom/user_list';
				let params = {
					nickName: this.searchUser,
					size: this.queryInfo_send.pagesize,
					page: this.queryInfo_send.pagenum,
				};
				this.fd_post(url, params).then((res) => {
					if(res.status) {
						that.send_total = res.total;
						that.user_data = res.list;
					} else {
						that.$message.error(res.msg);
					}
				}).catch((err) => {
					that.$message.error('网络错误');
				});
			},
			//页数改变
			handleSizeChange(newSize) {
				this.queryInfo.pagenum = 1
				this.queryInfo.pagesize = newSize
				this.getCouponList();
			},
			//页码改变
			handleCurrentChange(newPage) {
				this.queryInfo.pagenum = newPage
				this.getCouponList();
			},
			// 搜索
			search() {
				this.queryInfo.pagenum = 1
				this.getCouponList();
			},
			// 重置
			reset() {
				this.searchYHQ = '';
				this.getCouponList();
			},
			//switch发生变化
			changeState(item) {
				var url = 'coupon/updateOnSale';
				let params = {
					coupon_id: item.coupon_id,
				};
				this.fd_post(url, params).then((res) => {
					if(res.status) {
						this.$message.success("操作成功");
					} else {
						this.$message.error(res.msg);
					}
				}).catch((err) => {
					this.$message.error('网络错误');
				});
			},
			see(id) {
				this.$router.push({
					path: '/spread_coupon/coupon_use',
					query: {
						coupon_id:id
					}
				})
			},
			//编辑
			edit(id) {
				this.$router.push({
					path: '/spread_coupon/coupon_add',
					query: {
						id
					}
				})
			},
			//删除
			remove(id) {
				this.$confirm('你确定删除吗?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					//删除操作
					var url = 'coupon/delete_coupon';
					let params = {
						coupon_id: id,
					};
					that.fd_post(url, params).then((res) => {
						if(res.status) {
							that.$message.success("操作成功");
							that.getCouponList();
						} else {
							that.$message.error(res.msg);
						}
					}).catch((err) => {
						that.$message.error('网络错误');
					});
				}).catch(() => {
					this.$message.info('已取消删除');
				});
			},
			send(item) {
				this.sendVisible = true;
				this.yhq_detail = item;
				this.yhq_count = new Array(this.queryInfo_send.pagesize).fill('')
				if(this.user_data.length == 0) {
					this.getUserList();
				}
			},
			readyCoupon(coupon_id){
				this.$confirm('您确定要生成优惠券吗,生成优惠券需等待一段时间，请耐心等待?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					//删除操作
					var url = 'coupon/ready_coupon';
					let params = {
						coupon_id: coupon_id,
					};
					that.fd_post(url, params).then((res) => {
						console.log(res)
						if(res.status) {
							that.$message.success("操作成功");
							that.getCouponList();
						} else {
							that.$message.error(res.msg);
						}
					}).catch((err) => {
						that.$message.error('网络错误');
					});
				}).catch(() => {
					this.$message.info('已取消删除');
				});
			},
			addYHQ() {
				this.$router.push('/spread_coupon/coupon_add')
			},
			//页数改变
			handleSizeChange_send(newSize) {
				this.queryInfo_send.pagenum = 1
				this.queryInfo_send.pagesize = newSize
				this.getUserList();
			},
			//页码改变
			handleCurrentChange_send(newPage) {
				this.queryInfo_send.pagenum = newPage
				this.getUserList();
			},
			//对话框搜索
			userSearch() {
				this.getUserList();
			},
			// 对话框重置
			resetUser() {
				this.searchUser = '';
				this.getUserList();
			},
			getRowKey(row) {
				return row.id
			},
			handleSelectionChange(val) {
				// this.selectUser = val
				// this.selectUser.map(item => item.count = 0)
				this.selectUser = val.map(record =>  record.wx_id );
				console.log(this.selectUser)
			},
			close() {
				this.sendVisible = false
			},
			submit() {
				//let copydata = this.user_data.reverse();
				if(this.selectUser.length === 0) return this.$message.error('请选择用户')
				let url = 'coupon/coupon_send';
				let params = {
					coupon_id: that.yhq_detail.coupon_id,
					user_ids: JSON.stringify(that.selectUser)
				};
				that.fd_post(url, params).then((res) => {
					this.selectUser = [];
					this.sendVisible = false;
					this.$refs.userTable.clearSelection();
					if(res.status) {
						that.$message.success('派发成功')
					} else {
						that.$message.error(res.msg);
					}
				}).catch((err) => {
					that.$message.error('网络错误');
				});
			}
		},
	}
</script>

<style lang="scss">
	.spread_coupon{
		.el-table .cell {
		    white-space: nowrap;
		}
		.btns{
			/*white-space: normal;*/
		}
	}
</style>
<style lang="scss" scoped>
	@import './Spread_coupon.scss';
</style>